import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { ApiService } from '@app/core';
import { ChannelCategory } from '@app/shared/domain';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ChannelCategoryService {
  private categories$: BehaviorSubject<ChannelCategory[]> = new BehaviorSubject<ChannelCategory[]>([]);
  private categoriesSearchQuery$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(private apiService: ApiService) {}

  fetchCategories(): Observable<ChannelCategory[]> {
    return this.apiService.get('/api/v3/channel-categories/').pipe(tap((res) => this.categories$.next(res.results)));
  }

  getCategories(): Observable<ChannelCategory[]> {
    return this.categories$.asObservable().pipe(distinctUntilChanged());
  }

  addChannelToCategory(category: string, channel: string): Observable<any> {
    return this.apiService.patch(`/api/v3/channel-categories/${category}/add-channel/${channel}/`).pipe(
      tap((updatedCategory) => {
        // if UUID matches, update category with data from response
        const updatedCategories = this.categories$
          .getValue()
          .map((category) => (category.uuid === updatedCategory.uuid ? updatedCategory : category));
        this.categories$.next(updatedCategories);
      })
    );
  }

  removeChannelFromCategory(category: string, channel: string): Observable<any> {
    return this.apiService.patch(`/api/v3/channel-categories/${category}/remove-channel/${channel}/`).pipe(
      tap((updatedCategory) => {
        // if UUID matches, update category with data from response
        const updatedCategories = this.categories$
          .getValue()
          .map((category) => (category.uuid === updatedCategory.uuid ? updatedCategory : category));
        this.categories$.next(updatedCategories);
      })
    );
  }

  createChannelCategory(data: { title: string }): Observable<any> {
    return this.apiService.post('/api/v3/channel-categories/', data).pipe(
      tap((updatedCategory) => {
        // add newly created category to list of categories
        const updatedCategories = this.categories$.getValue();
        updatedCategories.push(updatedCategory);
        this.categories$.next(updatedCategories);
      })
    );
  }

  deleteCategory(category: string): Observable<any> {
    return this.apiService.delete(`/api/v3/channel-categories/${category}/`).pipe(
      tap(() => {
        // Remove category from list of categories
        const updatedCategories = this.categories$.getValue().filter((oldCategory) => oldCategory.uuid !== category);
        this.categories$.next(updatedCategories);
      })
    );
  }
  setSearchQuery(query: string): void {
    this.categoriesSearchQuery$.next(query);
  }

  getFilteredCategories(): Observable<ChannelCategory[]> {
    return combineLatest([this.categories$, this.categoriesSearchQuery$]).pipe(
      // Filtered categories based on search query
      map(([categories, searchQuery]) =>
        categories.filter((category) => category.title.toLowerCase().includes(searchQuery.toLowerCase()))
      )
    );
  }

  getCurrentCategories(channelUuid: string): Observable<ChannelCategory[]> {
    // Categories for the current channel
    return this.categories$.pipe(
      map((categories) => categories.filter((category) => category.channels.includes(channelUuid)))
    );
  }
}
