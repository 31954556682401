<h1 mat-dialog-title>{{ 'CREATE_NEW_CATEGORY' | translate }}</h1>
<div mat-dialog-content style="padding-top: 10px">
  <mat-form-field appearance="outline" class="w-100">
    <mat-label>{{ 'TITLE' | translate }}</mat-label>
    <input matInput [(ngModel)]="category.title" placeholder="Enter category title" />
  </mat-form-field>
  <mat-form-field appearance="outline" class="w-100">
    <mat-label>{{ 'CHANNELS' | translate }}</mat-label>
    <mat-select [(ngModel)]="category.channels" multiple>
      <mat-option *ngFor="let channel of channels$ | async" [value]="channel.uuid">
        {{ channel.title }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div mat-dialog-actions fxLayoutAlign="end center">
  <button mat-button (click)="onCancel()">Cancel</button>
  <button mat-raised-button color="primary" [disabled]="!category.title.trim()" (click)="onSave()">Create</button>
</div>
