<h1 mat-dialog-title>Edit Channel</h1>
<div mat-dialog-content>
  <app-spinner *ngIf="isLoadingDefault; else tplForm"></app-spinner>
  <ng-template #tplForm>
    <div class="channel-thumbnail" *ngIf="channel?.image">
      <img
        [src]="channel.image || '/assets/images/category-default.jpg'"
        onerror="this.src='/assets/images/category-default.jpg'"
        alt="channel-thumbnail"
      />
    </div>
    <input
      type="file"
      accept="image/*"
      hidden
      #inputUpload
      (change)="handleUploadChannelThumbnail($event.target.files)"
    />
    <button [style.margin-bottom]="'20px'" mat-flat-button color="primary" (click)="inputUpload.click()" class="w-100">
      UPLOAD NEW LIVE STREAM IMAGE
    </button>
    <form [formGroup]="channelForm">
      <div class="channel-fields" fxLayout="column">
        <mat-label class="text-weight-bold">Title</mat-label>
        <mat-form-field appearance="outline" class="w-100">
          <input type="text" formControlName="title" matInput placeholder="Please input title" />
          <mat-error *ngIf="f.title.invalid && f.title.touched">Title is required</mat-error>
        </mat-form-field>
        <mat-label class="text-weight-bold">Description</mat-label>
        <mat-form-field appearance="outline" class="w-100">
          <textarea formControlName="description" matInput placeholder="Please input description"></textarea>
          <mat-error *ngIf="f.description.invalid && f.description.touched">Description is required</mat-error>
        </mat-form-field>
        <mat-label><span class="text-weight-bold">Stream Key</span> <i>(Stream Key for your channel)</i></mat-label>
        <mat-form-field appearance="outline" class="w-100">
          <input type="text" formControlName="streamKey" matInput placeholder="Please input stream key" readonly />
          <mat-error *ngIf="f.streamKey.invalid && f.streamKey.touched">Stream key is required</mat-error>
        </mat-form-field>
        <mat-label class="text-weight-bold">Stream Url</mat-label>
        <mat-form-field appearance="outline" class="w-100">
          <input type="text" formControlName="streamUrl" matInput placeholder="Please input stream url" readonly />
          <mat-error *ngIf="f.streamUrl.invalid && f.streamUrl.touched">Stream url is required</mat-error>
        </mat-form-field>
        <mat-label class="text-weight-bold">{{ 'STREAM_HLS_URL' | translate }}</mat-label>
        <mat-form-field appearance="outline" class="w-100">
          <input type="text" matInput placeholder="Please input stream url" readonly [value]="streamUrl" />
        </mat-form-field>
        <!-- <div>{{streamUrl}}</div> -->
        <!-- <mat-label
          ><span class="text-weight-bold">Package:</span>
          <i>(This will be autogenerated if Stream Key is present)</i></mat-label
        >
        <mat-form-field appearance="outline" class="w-100">
          <mat-select formControlName="plan" placeholder="Please select package">
            <mat-option *ngFor="let item of channelPackages" [value]="item.uuid">{{ item.title }}</mat-option>
          </mat-select>
          <mat-error *ngIf="f.plan.invalid && f.plan.touched">Package is required</mat-error>
        </mat-form-field> -->
        <mat-label class="text-weight-bold">Live Stream Subscription Plans</mat-label>
        <mat-form-field appearance="outline" class="w-100">
          <mat-select
            formControlName="live_stream_subscription_plans"
            placeholder="Please select Subscription Plans"
            multiple
          >
            <mat-option *ngFor="let item of subscriptionPlans" [value]="item.uuid">{{ item.title }}</mat-option>
          </mat-select>
          <mat-error *ngIf="f.plan.invalid && f.plan.touched">Subscription Plans are required</mat-error>
        </mat-form-field>
        <mat-label class="text-weight-bold">Channel Profile</mat-label>
        <mat-form-field appearance="outline" class="w-100">
          <mat-select formControlName="channelProfile" placeholder="Please select channel profile" disabled>
            <mat-option [value]="'_2500KBPS'">1280x720@2500kbps</mat-option>
            <mat-option [value]="'_3000KBPS'">1920x1080@3000kbps</mat-option>
            <mat-option [value]="'_4000KBPS'">1920x1080@4000kbps</mat-option>
          </mat-select>
          <mat-error *ngIf="f.channelProfile.invalid && f.channelProfile.touched"
            >Channel profile is required</mat-error
          >
        </mat-form-field>
        <mat-label class="text-weight-bold">Timezone</mat-label>
        <mat-form-field appearance="outline" class="w-100">
          <mat-select formControlName="timezone" placeholder="Please select channel timezone">
            <mat-option>
              <ngx-mat-select-search placeholderLabel="Search" [formControl]="timezoneSearch"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let item of filteredTimezone | async" [value]="item.key">{{ item.value }}</mat-option>
          </mat-select>
          <mat-error *ngIf="f.timezone.invalid && f.timezone.touched">Timezone is required</mat-error>
        </mat-form-field>
        <mat-label class="text-weight-bold">{{ 'GENRES' | translate }}</mat-label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="genres" multiple>
            <mat-option *ngFor="let genre of genres" [value]="genre.value">
              {{ genre.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-label class="text-weight-bold">Categories</mat-label>
        <div fxLayoutAlign="space-between start" class="mb-16">
          <!-- trigger button -->
          <button mat-stroked-button class="button" [matMenuTriggerFor]="categoryMenu" [loading]="isCategoryLoading">
            Add to Existing Category
            <mat-icon>expand_more</mat-icon>
          </button>

          <!-- search input -->
          <mat-menu #categoryMenu="matMenu" [overlapTrigger]="false">
            <form (click)="$event.stopPropagation()">
              <mat-form-field appearance="fill" style="width: 100%">
                <input
                  matInput
                  [(ngModel)]="categorySearch"
                  placeholder="Search"
                  (ngModelChange)="handleSearchCategory($event)"
                  style="width: 100%"
                />
              </mat-form-field>
            </form>

            <!-- category list -->
            <div
              matTooltip="{{ categorySearch }}"
              matTooltipPosition="right"
              matTooltipShowDelay="400"
              [matTooltipDisabled]="!categorySearch"
            >
              <button
                mat-button
                color="primary"
                [loading]="isCategoryCreationLoading"
                class="category-item ellipsis"
                (click)="handleCreateChannelCategory(categorySearch)"
                style="width: 100%"
              >
                <mat-icon> add </mat-icon>
                Create New Category
              </button>
            </div>
            <div fxLayout="column" *ngIf="(filteredCategories$ | async)?.length > 0; else noCategoryResults">
              <div
                fxLayout="row"
                fxLayoutAlign="space-between center"
                *ngFor="let category of filteredCategories$ | async"
              >
                <button
                  mat-button
                  [matTooltip]="category.title"
                  matTooltipPosition="right"
                  matTooltipShowDelay="100"
                  class="category-item ellipsis"
                  fxFlex="90"
                  (click)="addToCategory(category)"
                  [disabled]="checkDisabledCategory(category)"
                >
                  {{ category.title }}
                </button>
                <button mat-button (click)="deleteCategory(category)" fxFlex="10" style="padding: 10px">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </div>

            <!-- no results -->
            <ng-template #noCategoryResults><p style="padding: 10px">No categories found</p></ng-template>
          </mat-menu>
        </div>
        <div *ngIf="currentCategories$ | async as currentCategories">
          <mat-chip-list>
            <mat-chip *ngFor="let category of currentCategories">
              <div
                style="max-width: 100px"
                matTooltip="{{ category.title }}"
                matTooltipPosition="right"
                matTooltipShowDelay="400"
              >
                <div class="ellipsis">{{ category.title }}</div>
              </div>
              <mat-icon matChipRemove (click)="removeFromCategory(category)">cancel</mat-icon>
            </mat-chip>
          </mat-chip-list>
        </div>
        <mat-checkbox color="primary" disabled formControlName="isActive">Active Channel</mat-checkbox>
        <!-- <mat-checkbox color="primary" formControlName="isDefault">Default Channel Set as active</mat-checkbox> -->
        <!-- <mat-checkbox color="primary" formControlName="isResetStreamKey"
          >Reset Stream Key Used to reset stream key for a given channel</mat-checkbox
        > -->
      </div>
    </form>
  </ng-template>
</div>
<div mat-dialog-actions>
  <mat-slide-toggle [checked]="isVisible" (change)="doToggleVisible($event)" color="primary">Visible</mat-slide-toggle>
  <button
    mat-flat-button
    class="mat-small"
    [style.margin-left]="'auto'"
    color="primary"
    [loading]="isLoading"
    (click)="handleUpdate()"
  >
    Update
  </button>
</div>
