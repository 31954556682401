import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import videojs from 'video.js';
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { ChannelService } from '@app/shared/services';

@Component({
  selector: 'app-stream-play-v2',
  templateUrl: './stream-play-v2.component.html',
  styleUrls: ['./stream-play-v2.component.scss'],
})
export class StreamPlayV2Component implements OnInit, OnDestroy {
  @ViewChild('videoPlayer', { static: true }) videoPlayer: ElementRef;
  @ViewChild('placeHolder') placeHolder: ElementRef<HTMLDivElement>;
  @ViewChild('videoContainer') videoContainer: ElementRef<HTMLDivElement>;
  player: any;
  streamHlsUrl: string;
  isLive: boolean = false;
  mode: string = 'channel';
  private subscriptions: Subscription = new Subscription();
  private worker: Worker | null = null;

  constructor(public channelService: ChannelService) {}

  ngOnInit(): void {
    this.initializeVideoPlayer();
    this.startPreventThrottlingWorker();
    this.subscriptions.add(
      this.channelService.streamHls$.subscribe((url) => {
        this.streamHlsUrl = url;
        // Handle changes in streamHlsUrl
        this.initializeVideoPlayer();
      })
    );

    this.subscriptions.add(
      this.channelService.isLive$.subscribe((isLive) => {
        this.isLive = isLive;
        // Handle changes in isLive
        this.initializeVideoPlayer();
      })
    );

    this.subscriptions.add(
      this.channelService.mode$.subscribe((mode) => {
        this.mode = mode;
        // Handle changes in mode
        this.initializeVideoPlayer();
      })
    );
  }

  startPreventThrottlingWorker() {
    if (typeof Worker !== 'undefined') {
      this.worker = new Worker('./assets/prevent-throttling.worker.js');
      this.worker.onmessage = (event) => {
        if (event.data === 'keepAlive') {
          this.keepVideoAlive();
        }
      };

      this.worker.postMessage('start');
    }
  }

  keepVideoAlive() {
    if (this.player && this.player.paused()) {
      this.player.play().catch(() => console.warn('Autoplay prevented.'));
    }
  }

  initializeVideoPlayer() {
    this.player = videojs(
      this.videoPlayer.nativeElement,
      {
        liveui: true,
        autoplay: true,
        muted: true,
      },
      () => {
        console.log('Video player ready');
        this.player.src({
          src: this.streamHlsUrl,
          type: 'application/x-mpegURL',
        });

        if ((this.isLive && this.mode == 'broadcast') || this.mode == 'channel') {
          this.setHLSElementsVisibility(true);
          this.setPlaceHolderVisibility(false);
          this.player.play();
        } else {
          this.setHLSElementsVisibility(false);
          this.setPlaceHolderVisibility(true);
        }
      }
    );
  }

  setPlaceHolderVisibility(show: any) {
    this.placeHolder.nativeElement.style.display = show === true ? 'block' : 'none';
  }

  setHLSElementsVisibility(show: any) {
    this.videoContainer.nativeElement.style.display = show === true ? 'block' : 'none';
  }

  ngOnDestroy(): void {
    if (this.player) {
      this.player.dispose();
    }
    if (this.worker) {
      this.worker.postMessage('stop');
      this.worker.terminate();
    }
    this.subscriptions.unsubscribe();
    // this.destroy$.next();
    // this.destroy$.complete();
  }
}
