import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Channel, ChannelCategory } from '@app/shared/domain';
import { ChannelService } from '@app/shared/services';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-create-channel-category-dialog',
  templateUrl: './create-channel-category-dialog.component.html',
  styleUrls: ['./create-channel-category-dialog.component.scss'],
})
export class CreateChannelCategoryDialogComponent implements OnInit {
  category: ChannelCategory;
  channels$: Observable<Channel[]>;

  constructor(
    private dialogRef: MatDialogRef<CreateChannelCategoryDialogComponent>,
    private channelService: ChannelService,
    @Inject(MAT_DIALOG_DATA) public data: ChannelCategory
  ) {}

  ngOnInit() {
    this.category = this.data;
    this.channels$ = this.channelService.getAllChannelList();
  }

  onSave() {
    if (this.category.title.trim()) {
      this.dialogRef.close(this.category);
    }
  }

  onCancel() {
    this.dialogRef.close(null);
  }
}
