import { Package } from './package.domain';
import { SubscriptionPlan } from './subscription-plan.domain';

export interface ChannelCategory {
  uuid: string;
  title: string;
  channels: string[];
}

export interface Channel {
  uuid?: string;
  title: string;
  slug: string;
  channel_profile: string;
  image?: any;
  default: boolean;
  active: boolean;
  featured?: boolean;
  package: Package;
  live_stream_subscription_plans: SubscriptionPlan[];
  live_stream_subscription_plans_uuids: string[];
  stream_token: string;
  stream_key: string;
  stream_url: string;
  stream_mode: 'EXTERNAL' | 'INTERNAL';
  reset_stream_key: boolean;
  description: string;
  timezone: string;
  stream_hls_url?: string;
  status?: string;
  selected?: boolean;
  thumbnail?: any;
  stream_username: string;
  stream_password: string;
  player_url?: string;
  recording_url?: string;
  ant_socket_url?: string;
  stream_hls_url_base?: string;
  is_visible?: string;
  is_use_scale_engine?: boolean;
  tvs_rtmp_live_url?: string;
  tvs_rtmp_vod_url?: string;
  tvs_rtmp_placeholder_url?: string;
  genres: { value: string; label: string[] }[];
}

export interface ChannelDefault {
  stream_key: string;
  stream_url: string;
  channel_profile: string;
}

export interface ChannelTimezone {
  key: string;
  value: string;
}

export enum StreamType {
  PLAYLIST,
  BROADCASTING,
}
