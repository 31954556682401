import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@app/core';
import { Channel } from '../../domain';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { distinctUntilChanged, first, map as rxjsMap, tap } from 'rxjs/operators';
import { map } from 'lodash';
import { NotifierService } from 'angular-notifier';
import { VideoPlaylistService } from '../video-playlist';
import { AudioPlaylistService } from '../audio-playlist';
import { environment } from '@env/environment';

export interface TimeZone {
  key: string;
  value: string;
}

@Injectable({
  providedIn: 'root',
})
export class ChannelService {
  private readonly allChannelListSubject$: BehaviorSubject<Channel[]> = new BehaviorSubject<Channel[]>([]);
  private readonly channelListSubject$: BehaviorSubject<Channel[]> = new BehaviorSubject<Channel[]>([]);
  private readonly activeChannelSubject$: BehaviorSubject<Channel | null> = new BehaviorSubject<Channel | null>(null);
  private readonly dropDownChannelLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly isRecording$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly isUseScaleEngine$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  // shifted channel variable there from env file
  private readonly playerUrl$: BehaviorSubject<string> = new BehaviorSubject<string>(
    'https://tvsw1-chorigin.secdn.net/tvsw1/'
  );
  private readonly streamUrl$: BehaviorSubject<string> = new BehaviorSubject<string>(
    'https://tvsw1-hls.secdn.net/tvsw1-chorigin/play/'
  );
  private readonly antSocketUrl$: BehaviorSubject<string> = new BehaviorSubject<string>(
    'ws://tvsw1-chorigin.secdn.net/tvsw1/websocket'
  );
  private readonly recordingUrl$: BehaviorSubject<string> = new BehaviorSubject<string>(
    'https://tvsw1-vod.secdn.net/tvsw1-vod/play/sestore3/tvsw1/'
  );
  private readonly streamHlsUrl$: BehaviorSubject<string> = new BehaviorSubject<string>(
    'https://tvsw1-chorigin.secdn.net/tvsw1/hls'
  );
  private readonly tvsRtmpLiveUrl$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private readonly tvsRtmpVodUrl$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private readonly tvsRtmpPlaceholderUrl$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private readonly isPlayingTvsRtmp$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly streamTypeTvsRtmp$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private readonly hasPlaceholder$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private streamHlsUrlSubject = new BehaviorSubject<string>('');
  private isLiveSubject = new BehaviorSubject<boolean>(false);
  private modeSubject = new BehaviorSubject<string>('channel');
  streamHls$ = this.streamHlsUrlSubject.asObservable();
  isLive$ = this.isLiveSubject.asObservable();
  mode$ = this.modeSubject.asObservable();

  constructor(
    private apiService: ApiService,
    private notifier: NotifierService,
    private videoPlaylistService: VideoPlaylistService,
    private audioPlaylistService: AudioPlaylistService
  ) {}

  setStreamHls(url: string) {
    this.streamHlsUrlSubject.next(url);
  }

  setIsLive(isLive: boolean) {
    this.isLiveSubject.next(isLive);
  }

  setMode(mode: string) {
    this.modeSubject.next(mode);
  }

  getPlayerUrl() {
    return this.playerUrl$.asObservable().pipe(distinctUntilChanged());
  }

  setPlayerUrl(playerUrl: string) {
    this.playerUrl$.next(playerUrl);
  }

  getStreamHlsUrl() {
    return this.streamHlsUrl$.asObservable().pipe(distinctUntilChanged());
  }

  setStreamHlsUrl(playerUrl: string) {
    this.streamHlsUrl$.next(playerUrl);
  }

  getTvsRtmpLiveUrl() {
    return this.tvsRtmpLiveUrl$.asObservable().pipe(distinctUntilChanged());
  }

  setTvsRtmpLiveUrl(playerUrl: string) {
    this.tvsRtmpLiveUrl$.next(playerUrl);
  }

  getTvsRtmpVodUrl() {
    return this.tvsRtmpVodUrl$.asObservable().pipe(distinctUntilChanged());
  }

  setTvsRtmpVodUrl(playerUrl: string) {
    this.tvsRtmpVodUrl$.next(playerUrl);
  }

  getTvsRtmpPlaceholderUrl() {
    return this.tvsRtmpPlaceholderUrl$.asObservable().pipe(distinctUntilChanged());
  }

  setTvsRtmpPlaceholderUrl(playerUrl: string) {
    this.tvsRtmpPlaceholderUrl$.next(playerUrl);
  }

  getIsPlayingTvsRtmp() {
    return this.isPlayingTvsRtmp$.asObservable().pipe(distinctUntilChanged());
  }

  setIsPlayingTvsRtmp(isPlaying: boolean) {
    this.isPlayingTvsRtmp$.next(isPlaying);
  }

  getHasPlaceholder() {
    return this.hasPlaceholder$.asObservable().pipe(distinctUntilChanged());
  }

  setHasPlaceholder(hasPlaceholder: boolean) {
    this.hasPlaceholder$.next(hasPlaceholder);
  }

  getStreamTypeTvsRtmp() {
    return this.streamTypeTvsRtmp$.asObservable().pipe(distinctUntilChanged());
  }

  setStreamTypeTvsRtmp(type: string) {
    this.streamTypeTvsRtmp$.next(type);
  }

  getStreamUrl() {
    return this.streamUrl$.asObservable().pipe(distinctUntilChanged());
  }

  setStreamUrl(streamUrl: string) {
    this.streamUrl$.next(streamUrl);
  }

  getAntSocketUrl() {
    return this.antSocketUrl$.asObservable().pipe(distinctUntilChanged());
  }

  setAntSocketUrl(antSocketUrl: string) {
    this.antSocketUrl$.next(antSocketUrl);
  }

  getRecordingUrl() {
    return this.recordingUrl$.asObservable().pipe(distinctUntilChanged());
  }

  setRecordingUrl(recordingUrl: string) {
    this.recordingUrl$.next(recordingUrl);
  }

  setIsRecording(isRecording: boolean) {
    this.isRecording$.next(isRecording);
  }

  getIsRecording() {
    return this.isRecording$.asObservable().pipe(distinctUntilChanged());
  }

  setIsUseScaleEngine(isUseScaleEngine: boolean) {
    this.isUseScaleEngine$.next(isUseScaleEngine);
  }

  getIsUseScaleEngine() {
    return this.isUseScaleEngine$.asObservable().pipe(distinctUntilChanged());
  }

  setActiveChannel(channel: Channel) {
    this.activeChannelSubject$.next(channel);
    // console.log(channel);
    // this.getChannelVariables(channel.uuid).subscribe(
    //   (res) => {
    //     console.log(res);
    //     environment.streamUrl = res.streamUrl;
    //     environment.antSocketUrl = res.antSocketUrl;
    //     environment.playerUrl = res.playerUrl;
    //   },
    //   (err) => {
    //     this.notifier.notify('error', err);
    //   }
    // );
  }

  getActiveChannel() {
    return this.activeChannelSubject$.asObservable().pipe(distinctUntilChanged());
  }

  changeActiveChannel(channel: Channel) {
    this.videoPlaylistService.setSelectedPlaylist(null);
    this.audioPlaylistService.setSelectedPlaylist(null);
    const _observables: Array<Observable<any>> = [];
    this.getActiveChannel()
      .pipe(first())
      .subscribe((currentActiveChannel) => {
        this.setDropDownChannelLoading(true);
        if (currentActiveChannel) {
          _observables.push(this.updateChannel({ selected: false } as Channel, currentActiveChannel.uuid));
        }
        _observables.push(this.updateChannel({ selected: true } as Channel, channel.uuid));
        forkJoin(_observables).subscribe(
          (res: any) => {
            console.log(res);
            const activeChannel = res.find((channelObj: Channel) => channelObj.selected && channelObj.active);
            this.setPlayerUrl(activeChannel.player_url);
            this.setStreamUrl(activeChannel.stream_hls_url_base);
            this.setAntSocketUrl(activeChannel.ant_socket_url);
            this.setRecordingUrl(activeChannel.recording_url);
            this.setStreamHlsUrl(activeChannel.stream_hls_url);
            this.setIsUseScaleEngine(activeChannel.is_use_scale_engine);
            this.setTvsRtmpLiveUrl(activeChannel.tvs_rtmp_live_url);
            this.setTvsRtmpVodUrl(activeChannel.tvs_rtmp_vod_url);
            this.setTvsRtmpPlaceholderUrl(activeChannel.tvs_rtmp_placeholder_url);
            // this.setDropDownChannelLoading(true);
            // this.setActiveChannel(channel);
            this.setActiveChannel(activeChannel);
          },
          (error) => {
            this.notifier.notify('error', error);
            this.setDropDownChannelLoading(false);
          },
          () => {
            this.setDropDownChannelLoading(false);
          }
        );
      });
  }

  setChannelList(channels: Channel[]) {
    this.channelListSubject$.next(channels);
  }

  getChannelList() {
    return this.channelListSubject$.asObservable().pipe(distinctUntilChanged());
  }

  getAllChannelList() {
    return this.allChannelListSubject$.asObservable().pipe(distinctUntilChanged());
  }

  setDropDownChannelLoading(loading: boolean) {
    this.dropDownChannelLoading$.next(loading);
  }

  getDropDownChannelLoading() {
    return this.dropDownChannelLoading$.asObservable().pipe(distinctUntilChanged());
  }

  getDropDownChannel() {
    this.setDropDownChannelLoading(true);
    this.getAllChannels()
      .pipe(rxjsMap((channels) => (channels ?? []).filter((c) => c.stream_mode === 'INTERNAL' && c.active)))
      .subscribe(
        (channels) => {
          this.setChannelList(channels);
          const activeChannel = channels.find((channel: Channel) => channel.selected && channel.active);
          this.setPlayerUrl(activeChannel.player_url);
          this.setStreamUrl(activeChannel.stream_hls_url_base);
          this.setAntSocketUrl(activeChannel.ant_socket_url);
          this.setRecordingUrl(activeChannel.recording_url);
          this.setStreamHlsUrl(activeChannel.stream_hls_url);
          this.setIsUseScaleEngine(activeChannel.is_use_scale_engine);
          this.setTvsRtmpLiveUrl(activeChannel.tvs_rtmp_live_url);
          this.setTvsRtmpVodUrl(activeChannel.tvs_rtmp_vod_url);
          this.setTvsRtmpPlaceholderUrl(activeChannel.tvs_rtmp_placeholder_url);
          if (!activeChannel && channels.length) {
            this.updateChannel({ selected: true } as Channel, channels[0].uuid).subscribe(
              (res) => this.setDropDownChannelLoading(false),
              (err) => {
                this.notifier.notify('error', 'Something went wrong');
                this.setDropDownChannelLoading(false);
              }
            );
            this.setActiveChannel(channels[0]);
          } else {
            this.setActiveChannel(activeChannel);
            this.setDropDownChannelLoading(false);
          }
        },
        (error) => {
          console.log(error);
          this.notifier.notify('error', 'Something went wrong when fetching channel');
          this.setDropDownChannelLoading(false);
        }
      );
    this.setDropDownChannelLoading(false);
  }

  getChannels(data?: any): Observable<any> {
    let params = new HttpParams();
    map(data, (value: any, key: string) => {
      if (value) {
        params = params.set(key, value);
      }
    });
    return this.apiService.get('/api/v1/channels/', params);
  }

  getAllChannels(data?: any): Observable<Channel[]> {
    return this.apiService.get('/api/v1/channel/list/').pipe(tap((res) => this.allChannelListSubject$.next(res)));
  }

  getAllChannelsByTenantId(tenant_id: string): Observable<Channel[]> {
    return this.apiService.get(`/api/v3/channels/list/${tenant_id}/`);
  }

  getChannel(channelId: string): Observable<any> {
    return this.apiService.get(`/api/v1/channel/single/${channelId}/`);
  }

  getChannelDefault(): Observable<any> {
    return this.apiService.get('/api/v1/channels/get-channel-default/');
  }

  getChannelTimezones(): Observable<TimeZone[]> {
    return this.apiService.get('/api/v1/playlists/timezones/');
  }

  updateChannel(channel: any, channelId: string): Observable<any> {
    return this.apiService.patch(`/api/v1/channels/${channelId}/`, channel);
  }

  createChannel(channel: any): Observable<any> {
    return this.apiService.post(`/api/v1/channels/`, channel);
  }

  deleteChannel(channelId: string): Observable<any> {
    return this.apiService.delete(`/api/v1/channels/${channelId}/`);
  }
  uploadImage(channelId: string, data: FormData): Observable<any> {
    return this.apiService.post(`/api/v1/channels/${channelId}/upload-image/`, data);
  }

  changeOrder(data: any[]): Observable<any> {
    return this.apiService.post('/api/v1/channels/change-order/', data);
  }

  getClientChannel(channelId: string): Observable<any> {
    return this.apiService.get(`/api/v1/clients/channel/${channelId}/`);
  }

  registerBroadcast(channel: any): Observable<any> {
    return this.apiService.post('/api/v1/playlists/register-broadcast/', channel);
  }

  additionalChannelRequest(channel: any): Observable<any> {
    return this.apiService.post(`/api/v1/channels/additional-channel-request/`, channel);
  }

  getChannelsCount(): Observable<any> {
    return this.apiService.get(`/api/v1/channels/channels-count/`);
  }

  // get channel status from ScaleEngine
  getChannelStatus(channelId: string): Observable<any> {
    return this.apiService.get(`/api/v1/channels/channel-status/${channelId}/`);
  }

  // get channel variables
  getChannelVariables(channelId: string): Observable<any> {
    return this.apiService.get(`/api/v1/channels/channel-variables/${channelId}/`);
  }

  getChannelStreamStatus(channelId: string): Observable<any> {
    return this.apiService.get(`/api/v1/channels/check-stream-status/${channelId}/`);
  }

  resetChannelStream(channelId: string): Observable<any> {
    return this.apiService.post(`/api/v1/channels/${channelId}/reset-stream/`);
  }

  syncChannelStream(channelId: string): Observable<any> {
    return this.apiService.post(`/api/v1/channels/${channelId}/sync-stream/`);
  }

  rebootChannel(channelId: string): Observable<any> {
    return this.apiService.post(`/api/v1/channels/${channelId}/reboot-channel/`);
  }

  getNowPlayingPlaylist(channelId: string): Observable<any> {
    return this.apiService.get(`/api/v1/clients/channel/${channelId}/now-playing-playlist/`);
  }

  getNowPlayingVideo(channelId: string): Observable<any> {
    return this.apiService.get(`/api/v1/clients/channel/${channelId}/now-playing-video-playlist/`);
  }

  startPlaceHolderStream(channelId: string): Observable<any> {
    return this.apiService.post(`/api/v1/channels/${channelId}/start-placeholder-stream/`);
  }
}
