<h1 mat-dialog-title *ngIf="!channel">Create external channel stream</h1>
<h1 mat-dialog-title *ngIf="!!channel">Edit external channel stream</h1>
<div mat-dialog-content>
  <app-spinner *ngIf="isLoading; else tplForm"></app-spinner>
  <ng-template #tplForm>
    <div class="channel-thumbnail" class="w-100" *ngIf="channelThumbnail">
      <img
        class="w-100"
        [src]="channelThumbnail"
        alt="channel-thumbnail"
        onerror="this.src='/assets/images/category-default.jpg'"
      />
    </div>
    <input
      type="file"
      accept="image/*"
      [src]="channelImage"
      hidden
      #inputUpload
      (change)="handleUploadChannelThumbnail($event.target.files)"
    />
    <button [style.margin-bottom]="'20px'" mat-flat-button color="primary" (click)="inputUpload.click()" class="w-100">
      UPLOAD NEW LIVE STREAM IMAGE
    </button>
    <form [formGroup]="channelForm">
      <div class="channel-fields" fxLayout="column">
        <mat-label class="text-weight-bold">Title:</mat-label>
        <mat-form-field appearance="outline" class="w-100">
          <input type="text" formControlName="title" matInput placeholder="Please input title" />
          <mat-error *ngIf="f.title.invalid && f.title.touched">Title is required</mat-error>
        </mat-form-field>
        <mat-label class="text-weight-bold">Description:</mat-label>
        <mat-form-field appearance="outline" class="w-100">
          <textarea formControlName="description" matInput placeholder="Please input description"></textarea>
          <mat-error *ngIf="f.description.invalid && f.description.touched">Description is required</mat-error>
        </mat-form-field>

        <mat-label class="text-weight-bold">Stream Url:</mat-label>
        <mat-form-field appearance="outline" class="w-100">
          <input type="text" formControlName="streamUrl" matInput placeholder="Please input stream url" />
          <mat-error *ngIf="f.streamUrl.invalid && f.streamUrl.touched">Stream url is required</mat-error>
        </mat-form-field>
        <mat-label class="text-weight-bold">{{ 'GENRES' | translate }}:</mat-label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="genres" multiple>
            <mat-option *ngFor="let genre of genres" [value]="genre.value">
              {{ genre.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <!-- Channel needs to be already existing for JS implementation of categories to work  -->
        <ng-container *ngIf="!!channel">
          <mat-label class="text-weight-bold">Categories:</mat-label>
          <div fxLayoutAlign="space-between start" class="mb-16">
            <!-- trigger button -->
            <button mat-stroked-button class="button" [matMenuTriggerFor]="categoryMenu" [loading]="isCategoryLoading">
              Add to Existing Category
              <mat-icon>expand_more</mat-icon>
            </button>

            <!-- search input -->
            <mat-menu #categoryMenu="matMenu" [overlapTrigger]="false">
              <form (click)="$event.stopPropagation()">
                <mat-form-field appearance="fill" style="width: 100%">
                  <input
                    matInput
                    [(ngModel)]="categorySearch"
                    placeholder="Search"
                    (ngModelChange)="handleSearchCategory($event)"
                    style="width: 100%"
                  />
                </mat-form-field>
              </form>

              <!-- category list -->
              <div
                matTooltip="{{ categorySearch }}"
                matTooltipPosition="right"
                matTooltipShowDelay="400"
                [matTooltipDisabled]="!categorySearch"
              >
                <button
                  mat-button
                  color="primary"
                  [loading]="isCategoryCreationLoading"
                  class="category-item ellipsis"
                  (click)="handleCreateChannelCategory(categorySearch)"
                  style="width: 100%"
                >
                  <mat-icon> add </mat-icon>
                  Create New Category
                </button>
              </div>
              <div fxLayout="column" *ngIf="(filteredCategories$ | async)?.length > 0; else noCategoryResults">
                <div
                  fxLayout="row"
                  fxLayoutAlign="space-between center"
                  *ngFor="let category of filteredCategories$ | async"
                >
                  <button
                    mat-button
                    [matTooltip]="category.title"
                    matTooltipPosition="right"
                    matTooltipShowDelay="100"
                    class="category-item ellipsis"
                    fxFlex="90"
                    (click)="addToCategory(category)"
                    [disabled]="checkDisabledCategory(category)"
                  >
                    {{ category.title }}
                  </button>
                  <button mat-button (click)="deleteCategory(category)" fxFlex="10" style="padding: 10px">
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </div>

              <!-- no results -->
              <ng-template #noCategoryResults><p style="padding: 10px">No categories found</p></ng-template>
            </mat-menu>
          </div>
          <div *ngIf="currentCategories$ | async as currentCategories">
            <mat-chip-list>
              <mat-chip *ngFor="let category of currentCategories">
                <div
                  style="max-width: 100px"
                  matTooltip="{{ category.title }}"
                  matTooltipPosition="right"
                  matTooltipShowDelay="400"
                >
                  <div class="ellipsis">{{ category.title }}</div>
                </div>
                <mat-icon matChipRemove (click)="removeFromCategory(category)">cancel</mat-icon>
              </mat-chip>
            </mat-chip-list>
          </div>
        </ng-container>
      </div>
    </form>
  </ng-template>
</div>
<div mat-dialog-actions>
  <mat-slide-toggle [checked]="isVisible" (change)="doToggleVisible($event)" color="primary">Visible</mat-slide-toggle>
  <button
    mat-flat-button
    class="mat-small"
    [style.margin-left]="'auto'"
    color="primary"
    [loading]="isLoading"
    (click)="handleUpdate()"
  >
    {{ !!channel ? 'Update' : ' Create' }}
  </button>
</div>
